// 5. Site Footer
.snFooterContainer {
  width: 100%;
  
  max-width: 100%;
  
  margin: 0 auto;
  background-color: $primary-color;
  > img {
    display: none;
  }
  h1, h2, h3, h4, h5, h6, .textBlockElement h3, .eventAggregatorElement .aggHeader, .aggHeader {
    font-family: inherit;
    font-weight: bold;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    margin: 0 0 10px 0;
  }
  li,
  p,
  a,
  .pageEl .textBlockElement blockquote {
    font-family: inherit;
    font-weight: normal;
    font-size: 15px;
    color: white;
    text-transform: initial;
    margin: 0 0 10px 0;
    padding: 0;
  }
  .layoutContainer {
    
    max-width: 1200px;
    margin: 0 auto;
    float: none;
    
    padding:20px 0px;
    &.first.last {
      padding:40px 0px;
    }
    &.first {
      padding-top:40px;
    }
    &.last {
      padding-bottom: 40px;
    }
    .pageElement {
      margin: 0;
    }
  }
  .footerLinks {
    text-align: center;
    a {
      font-family: $fontOne;
      font-weight: normal;
      font-size: 15px;
      color: white;
      text-transform: initial;
    }
  }
  .footerLogo {
    text-align: left;
    a {
      display: inline-flex;
      margin-bottom: 0;
    }
    img {
      
      
      padding:10px;
      
      
      width: 100%;
      height: 100%;
      max-width: 150px;
      @media only screen and (max-width: 1200px){ max-width: 150px; }
    }
  }
  .footerCallToAction {
    width: 100%;
    display:inline-block;
    text-align: left;
    a {
      
      border: 1px solid #fff;
      background-color: $primary-color;
      
      padding: 5px 15px;
      border-radius: 5px;
      font-family: $fontOne;
      font-weight: bold;
      font-size: 16px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      display: inline-block;
      margin-bottom: 0;
      :hover {
        text-decoration: none;
      }
    }
  }
  /* layoutVersions */
  &.layout-1 {
    .footerLinks {
      li {
        margin:0 10px;
        display: inline-block;
      }
    }
  }
  &.layout-2 {
    .footerLinks {
      li {
        margin: 0 0 20px 0;
        display: block;
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
  }
  .edit_mode & .layoutContainer{
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15 {
      padding-left: (15 * 1px);
      padding-right: (15 * 1px);
    }
  }
  .social-media-links {
    .sn-social-media-list .sn-social-media-icon {
      background-color: $secondary-color;
    }
    &:not(.light):not(.dark) .sn-social-media-icon:hover {
      background-color: $secondary-color;
    }
  }
}
/* breakpoints */
@media screen and (max-width: 1200px) {
  .snFooterContainer {
    .layout-100,
    .layout-50-50,
    .layout-33-33-33,
    .layout-25-25-25-25,
    .layout-20-20-20-20-20,
    .layout-16-16-16-16-16-16 {
      padding: 0;
      &.first.last {
        padding:40px 0px;
      }
      &.first {
        padding-top:40px;
      }
      &.last {
        padding-bottom: 40px;
      }
      .column {
        padding-top:40px;
        text-align: center;
        &:first-of-type {
          //forgot why I had this..
          //padding-top: 0;
        }
        &:last-of-type {
          //forgot why I had this..
          //padding-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: center !important;
      }
      .footerLogo,
      .footerLinks,
      .footerCallToAction {
        text-align: center;
      }
    }
    &.layout-1 {
      .layout-20-20-20-20-20 {
        &.first {
          padding-top:0;
        }
        .column {
          width: 100%;
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(5) {
            display: none;
          }
        }
      }
    }
    &.layout-2 {
      .layout-25-25-25-25 {
        //padding-top: 20px;
        padding-top: 0;
        .column {
          padding: 40px 0 0 0;
          width:50%;
          &:nth-of-type(odd) {
            clear: both;
          }
        }
      }
      .layout-20-20-20-20-20 {
        //padding-top: 20px;
        padding-top: 0;
        .column {
          padding: 40px 0 0 0;
          width: 50%;
          &:last-of-type {
            width: 100%;
          }
          &:nth-of-type(odd) {
            clear: both;
          }
        }
      }
      .layout-16-16-16-16-16-16 {
        //padding-top: 20px;
        padding-top: 0;
        .column {
          padding: 40px 0 0 0;
          width: 33.33%;
          &:nth-of-type(3n+4) {
            clear: both;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .snFooterContainer {
    .layout-100,
    .layout-50-50,
    .layout-33-33-33,
    .layout-25-25-25-25,
    .layout-20-20-20-20-20,
    .layout-16-16-16-16-16-16 {
      .column {
        width: 100%;
        float: none;
        text-align: center;
        padding: 40px 0 0 0;
        &:first-of-type {
          padding-top: 0;
        }
        &:last-of-type {
          padding-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: center !important;
      }
      .footerLogo,
      .footerLinks,
      .footerCallToAction {
        text-align: center;
      }
    }
    &.layout-1 {
      .layout-100 {
        .column {
          &:first-of-type {
            padding: 40px 0 0 0;
          }
        }
      }
    }
    &.layout-2 {
      .layout-100,
      .layout-50-50,
      .layout-33-33-33,
      .layout-25-25-25-25,
      .layout-20-20-20-20-20,
      .layout-16-16-16-16-16-16 {
        padding:20px 0px;
        &.first.last {
          padding:40px 0px;
        }
        &.first {
          padding-top:40px;
        }
        &.last {
          padding-bottom: 40px;
        }
        .column {
          width: 100%;
        }
      }
    }
  }
}
/* siteFooter */
#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  max-width: 1200px;
  clear: both;
  
  max-width:100vw;
  
  
  background-color: $primary-color;
  
  li,a {
  font-family: inherit;
  font-weight: normal;
  font-size: 13px;
  color: #eaeaea;
  text-transform: initial;
  
  line-height: 37px;
  
  margin:0;
  }
  > ul {
    padding: 0;
    @include displayCenter($maxWidth);
    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;
      a {
        display: inline-block;
      }
      &:hover {
        display: inline-block;
      }
    }
  }
}
#siteFooter li#poweredByNGIN {
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;
    &:after {
      
      content: url($asset-path-for+'/logo_images/logo.svg');
      
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }
    img {
      display: none;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }
  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }
  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
  }
}
